import React, { Component } from 'react'
import { Link } from 'react-router-dom';
// import banner_img from '../../assets/images/business-main.jpg';
// import mobile_img from '../../assets/images/biz-mobile1.png';
// import mobile_img_bg from '../../assets/images/biz-mobile-bg.png';

export default class BannerOne extends Component {
    constructor(props) {
        super(props);
        this.state = {
            location: 'New York, NY',
        }
    }

    componentDidMount() {
        var loc = JSON.parse(localStorage.getItem('location'));
        if (loc != null) {

            this.setState({
                location: loc.city + ', ' + loc.region
            })

        }
    }
    render() {
        return (
            <>
                <section className="hero-wrapper2 bizbanner">
                    {/* <div className="hero-overlay"></div> */}
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-12 col-12 col-px-12 px-0">
                                <div className="biz-ligh-back d-flex justify-content-center align-items-center">
                                    <div className="BannerContent text-center text-white d-flex">
                                        <h1 className='fw-bolder'>Katika is built on the power of community.</h1>
                                    </div>
                                    {/* <div className="BusinessInfo">
                                        <p>We connect your business to neighbors that live in your community. Building local economies through local neighborhood transactions is our mission.</p> 
                                        <button className="btn btn-default">Get Started for Free </button> 
                                        <Link to={'/business-sign-up'} className="btn btn-default">Create Your Free Business Page</Link> 
                                    </div> */}
                                </div>
                            </div>
                            {/* <div className="col-lg-6 px-0">
                                <div className="BusinessInfo banner_img desktop-show" style={{ backgroundImage: "url('" + banner_img + "')" }}>
                                    <div className="mobile-img"><img src={mobile_img} /></div>
                                </div>
                                <div className="BusinessInfo banner_img mobile-show" style={{ backgroundImage: "url('" + mobile_img_bg + "')" }}>
                                    <div className="mobile-img"><img src={mobile_img} /></div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </section>
            </>
        )
    }
}
