import React from 'react'
import { Link } from 'react-router-dom'
import { buyerUrl, frontLaravelUrl } from '../../config'

export default function Logo({url, className}) {
    return (
        <>
            <a href={` ${frontLaravelUrl}`} className={className}>
                <img  width="100%" height="51" className="auto-w-h hello2" src={url} alt="logo" />
            </a>
        </>
    )
}
