import React, { useEffect, useState } from 'react'
import { FiChevronDown } from 'react-icons/fi'
import { Link } from "react-router-dom";
import $ from 'jquery';
import { useHistory } from "react-router-dom";
import useToken, { uToken, uInfo, uRole } from '../../../useToken';
import User from '../../../assets/images/user.png';
import { Container, Row, Col } from 'react-bootstrap';
import Logo from "../../../components/business/Logo";
import Logosrc from '../../../assets/images/katika-logo.png';
import { buyerUrl, sellerUrl, communityUrl, frontLaravelUrl } from '../../../config';
export default function Navbar() {
    const [navOpen, setNavOpen] = useState(false);
    const [token, setToken] = useState(!uToken() ? '' : uToken());
    let history = useHistory();
    $(document).on('click', '.side-menu-ul li', function () {
        $(".side-menu-ul li").removeClass('active');
        $(this).toggleClass("active")
    })
    $(document).on('click', '.side-menu-ul li.active', function () {
        $(".side-menu-ul li.active").removeClass('active');
    })
    useEffect(() => {
    }, []);
    function LogoutBtn() {
        setToken('');
        localStorage.removeItem('token');
        // history.push("/");
        // setTimeout(
        //     () => history.push("/"),
        //     4000
        // );
    }



    return (
        <>
            <div className="main-menu-content BHome">
                <div className="TopHeader">
                    <Container>
                        <Row>
                            <Col lg={3} sm={3}>
                                <div className="logo listing">
                                    <Logo url={Logosrc} />
                                </div>
                            </Col>
                            <Col lg={9}>
                                <nav>
                                    <ul>
                                        <li key={0}>
                                            <a href={`${frontLaravelUrl}`}> Katika </a>
                                        </li>
                                        <li key={50}>
                                            <a href={`${sellerUrl}`}> Katika for Business</a>
                                        </li>
                                        <li key={1}>
                                            <a href="#"> Get to Know Us <FiChevronDown /></a>
                                            <ul className="dropdown-menu-item">
                                                <li key={10}><a href={`${frontLaravelUrl}/about-us`} >About Katika</a></li>
                                                <li key={2}><a href={`${frontLaravelUrl}/labs`} >Katika Labs</a></li>
                                                <li key={3}><a href={`${frontLaravelUrl}/partners`}>Partners</a></li>
                                                <li key={11}><a href={`${communityUrl}`}>Katika Community</a></li>
                                            </ul>
                                        </li>
                                        <li key={4}>
                                            <Link to="#"> Let us help you <FiChevronDown /></Link>
                                            <ul className="dropdown-menu-item">
                                                <li key={5}><a href={`${sellerUrl}`}>List Your Business</a></li>
                                                <li key={6}><a href={`${frontLaravelUrl}/faq`} >Frequently Asked Questions</a></li>
                                                <li key={15}>
                                                    <a href={`${sellerUrl}/plans`} >Plans & Pricing</a>
                                                </li>
                                                <li key={10}><a href={`${frontLaravelUrl}/get-help`} >Contact Us</a></li>
                                            </ul>
                                        </li>
                                        <li key={7}>
                                            <Link to="#"> Discover <FiChevronDown /></Link>
                                            <ul className="dropdown-menu-item">
                                                <li key={8}><a href={`${frontLaravelUrl}/press`} > Press</a></li>
                                                <li key={9}><a href="https://blog.katika.us/">Blog</a></li>
                                            </ul>
                                        </li>
                                        <li key={7}>
                                            <Link to="#"> Legal <FiChevronDown /></Link>
                                            <ul className="dropdown-menu-item">
                                                <li key={8}><a href={`${frontLaravelUrl}/terms-of-service`} > Terms of Use</a></li>
                                                <li key={9}><a href={`${frontLaravelUrl}/privacy-policy`}>Privacy Policy</a></li>
                                            </ul>
                                        </li>
                                    </ul>
                                </nav>
                            </Col>

                        </Row>
                    </Container>
                </div>
            </div>
            <div className="side-menu-open" onClick={() => setNavOpen(!navOpen)}>
                <span className="menu__bar"></span>
                <span className="menu__bar"></span>
                <span className="menu__bar"></span>
            </div>
            <div className={navOpen ? 'side-nav-container active' : 'side-nav-container'}>
                <div className="humburger-menu">
                    <div className="humburger-menu-lines side-menu-close" onClick={() => setNavOpen(!navOpen)}></div>
                </div>
                <div className="side-menu-wrap">
                    <ul className="side-menu-ul">
                        <li key={0}>
                            <a href={`${frontLaravelUrl}`}> Katika </a>
                        </li>
                        <li key={50}>
                            <a href={`${sellerUrl}`}> Katika for Business</a>
                        </li>
                        <li key={1}>
                            <a href="#" className='disabled-link'> Get to Know Us</a>
                            <ul className="dropdown-menu-item">
                                <li key={10}><a href={`${frontLaravelUrl}/about-us`} >About Katika</a></li>
                                <li key={2}><a href={`${frontLaravelUrl}/labs`} >Katika Labs</a></li>
                                <li key={3}><a href={`${frontLaravelUrl}/partners`}>Partners</a></li>
                                <li key={11}><a href={`${communityUrl}`}>Katika Community</a></li>
                            </ul>
                        </li>
                        <li key={4}>
                            <Link to="#" className='disabled-link'> Let us help you </Link>
                            <ul className="dropdown-menu-item">
                                <li key={5}><a href={`${sellerUrl}`}>List Your Business</a></li>
                                <li key={6}><a href={`${frontLaravelUrl}/faq`} >Frequently Asked Questions</a></li>
                                <li key={15}>
                                    <a href={`${sellerUrl}/plans`} >Plans & Pricing</a>
                                </li>
                                <li key={10}><a href={`${frontLaravelUrl}/get-help`} >Contact Us</a></li>
                            </ul>
                        </li>
                        <li key={7}>
                            <Link to="#" className='disabled-link'> Discover</Link>
                            <ul className="dropdown-menu-item">
                                <li key={8}><a href={`${frontLaravelUrl}/press`} > Press</a></li>
                                <li key={9}><a href="https://blog.katika.us/">Blog</a></li>
                            </ul>
                        </li>
                        <li key={7}>
                            <Link to="#" className='disabled-link'> Legal</Link>
                            <ul className="dropdown-menu-item">
                                <li key={8}><a href={`${frontLaravelUrl}/terms-of-service`} > Terms of Use</a></li>
                                <li key={9}><a href={`${frontLaravelUrl}/privacy-policy`}>Privacy Policy</a></li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
        </>
    )
}
