import React, { useState, useEffect } from 'react'
import { FiChevronDown } from 'react-icons/fi';
import { BsPower } from 'react-icons/bs';
import { AiOutlineShop, AiOutlineUser } from "react-icons/ai";
import { FaRegCheckCircle } from "react-icons/fa";
import { Link } from "react-router-dom";
import $ from 'jquery';
import { RiSearchLine } from 'react-icons/ri'

import Logo from "../common/Logo";
import { Container, Dropdown, NavDropdown } from "react-bootstrap";
import UserInfoSignIn from "../../components/user/UserInfoSignIn";
import Logosrc from '../../assets/images/katika-logo.png';
import Navigation from '../../assets/images/navigation.png'
import User from '../../assets/images/user.png';
import Logout from '../../assets/images/signout.png';
import Chat from '../../assets/images/chat4.svg';
import KIcon from '../../assets/images/katika_K.png';
import Notification from '../../assets/images/bell.png';
import Autocomplete from 'react-google-autocomplete';
import { AutoApiKey, ApiUrl, algoliaAppId, algoliaAppKeyId, sellerUrl, buyerUrl, frontLaravelUrl } from '../../config';
import useToken, { getGeoInfo, uRole, uInfo, uToken } from '../../useToken';
import axios from 'axios';
import Scrollbar from 'smooth-scrollbar-react';
import Autosuggest from 'react-autosuggest';
import Autosuggest1 from "../directories/AutoSuggest";

import algoliasearch from "algoliasearch";


export default function Navbar(props) {
	const [token, setToken] = useState();
	const [navOpen, setNavOpen] = useState(false);
	const [search, setSearch] = useState("all");
	const [location2, setLocation2] = useState(window.location.pathname.includes("/listing/") ? (props.state ? props.state.loc.replaceAll('-', ' ') + ', ' + props.state.state.toUpperCase() : "") : (props.state ? props.state.location : ""));
	const [location, setLocation] = useState(window.location.pathname.includes("/listing/") ? (props.state ? props.state.loc.replaceAll('-', ' ') + ', ' + props.state.state.toUpperCase() : "") : (props.state ? props.state.location : ""));




	console.log(props, 'ssssssss');

	const [lat, setLat] = useState(props.state ? props.state.lat : "")
	const [lng, setLng] = useState(props.state ? props.state.lng : "")
	const [showSuggest, setShowSuggest] = useState(false)
	const [categories, setCategories] = useState([]);
	const [place, setPlace] = useState();
	const [value, setValue] = useState("")
	const [suggestions, setSuggestion] = useState([])



	useEffect(() => {
		console.log(props, 'ssssssss')

		if (props.search_data) {
			setSearch(props.search_data.search);
			setLocation(props.search_data.location);
			setLocation2(props.search_data.location);
		} else if (props.state) {
			if (props.state.id != '' && props.state.id != undefined) {
				if (props.state.id == 'all') {
					setValue("");
				} else {
					let dtring = props.state.id.replace('-and-', ' & ').replaceAll('-', ' ');
					setSearch(dtring);
					setValue(dtring);
				}
			}

		} else if (props.url) {
			setSearch(props.url)
			setValue('url')
		}


		if (!location || location == 'undefined, undefined') {
			var locn = JSON.parse(localStorage.getItem('location'));
			if (!locn) {
				if (getGeoInfo() == undefined) {
					setLocation('Philadelphia, PA');
					setLocation2('Philadelphia, PA');
					setLat(39.9525839)
					setLng(-75.1652215)
				} else {
					setLocation(getGeoInfo())
					setLocation2(getGeoInfo());
					setLat(locn.latitude)
					setLng(locn.longitude)
				}

			} else {
				let string = locn.city + ', ' + locn.region;
				setLocation(string)
				setLocation2(string);
				setLat(locn.latitude)
				setLng(locn.longitude)
			}
			console.log(location, 'addd loation')
		}

		console.log(location, 'hi loation')
		getHomePageCategoriesList();
	}, []);

	const getSuggestionValue = (suggestion) => (suggestion.business_name) ? suggestion.business_name : suggestion.name

	const renderSuggestion = (suggestion, { query }) => <div className='bg-search-box'>{(suggestion.business_name ? <span>{suggestion.business_name}</span> : <span>{suggestion.name}</span>)} {query.length > 0 && (<span className='bg-search'>{query}</span>)}</div>;

	const onChange = (event, { newValue, method }) => {
		setValue(newValue)
		setSearch(newValue)
	}

	function onSuggestionsFetchRequested({ value }) {
		const client = algoliasearch(algoliaAppId, algoliaAppKeyId);
		const index = client.initIndex('katika_business');
		// Search for a first name
		index.search(value, {
			numericFilters: `is_active = 1,complete_percent > 69`,
			hitsPerPage: 10,
		}).then(({ hits }) => {
			setSuggestion(hits)
		});
	}


	function onSuggestionsClearRequested() {
		setSuggestion([]);
	};
	function shouldRenderSuggestions() {
		return true;
	};

	const getHomePageCategoriesList = () => {
		axios.get(ApiUrl + 'getHomePageCategoriesList').then((response) => {
			if (response.data.success) {
				// console.log("response.data.top_lists begin = ");
				// console.log(response.data.top_lists);
				// console.log("response.data.top_lists end = ");
				setCategories(response.data.top_lists)
			}
		}).catch((error) => {
			console.log(error);
		});
	}

	$(document).on('click', '.side-menu-ul li', function () {
		$(".side-menu-ul li").removeClass('active');
		$(this).toggleClass("active")
	})
	$(document).on('click', '.side-menu-ul li.active', function () {
		$(".side-menu-ul li.active").removeClass('active');
	})

	function LogoutBtn() {
		setToken('');
		localStorage.removeItem('token');
	}

	function Chandler(e) {
		let loca1 = JSON.parse(localStorage.getItem('location'));
		console.log("Chandler loca1 = "+loca1);
		console.log("Chandler loca1.city = "+loca1.city);
		console.log("Chandler loca1.region = "+loca1.region);
		console.log("Chandler loca1.latitude = "+loca1.latitude);
		console.log("Chandler loca1.longitude = "+loca1.longitude);
		setLocation(loca1.city + ', ' + loca1.region)
		setLocation2(loca1.city + ', ' + loca1.region);
		setLat(loca1.latitude)
		setLng(loca1.longitude)
	}

	const onSuggestionSelected = (event, { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }) => {
		console.log("onSuggestionSelected suggestion = "+suggestion);
		console.log("onSuggestionSelected suggestionValue = "+suggestionValue);
		console.log("onSuggestionSelected suggestionIndex = "+suggestionIndex);
		console.log("onSuggestionSelected sectionIndex = "+sectionIndex);
		console.log("onSuggestionSelected method = "+method);
		console.log("onSuggestionSelected location = "+location);
		console.log("onSuggestionSelected lat = "+lat);
		console.log("onSuggestionSelected lng = "+lng);
		if (suggestion.business_name) {
			setValue(suggestion.business_name);
			setSearch(suggestion.business_name)
			props.handler({ search: suggestion.business_name, location: location, lat: lat, lng: lng })
		} else {
			setValue(suggestion.name);
			setSearch(suggestion.name)
			props.handler({ search: suggestion.name, location: location, lat: lat, lng: lng })
		}
	}

	const handleSubmit = async e => {
		e.preventDefault();
		console.log("handleSubmit search = "+search);
		console.log("handleSubmit location = "+location);
		console.log("handleSubmit lat = "+lat);
		console.log("handleSubmit lng = "+lng);
		console.log("handleSubmit location2 = "+location2);
		props.handler({ search: search, location: location, lat: lat, lng: lng, location2: location2 })
	}

	const inputProps = {
		placeholder: 'Find any business or service',
		value,
		type: "text",
		onChange: onChange
	}; 

	return (
		<div className="container-fluid nav-listing-cat">
			<div className="nav-listing-search">
				<div className="row">
					<div className="col-lg-2 col-md-12">
						<div className="logo listing">
							<Logo url={Logosrc} />
						</div>
					</div>
					<div className="col-lg-10 col-md-12">
						<div className='d-flex'>
							<div className='w-100'>
							<div className="search-feild w-100">
								<div className="main-search-input-item search-input">
									<div className="contact-form-action">
										<form onSubmit={handleSubmit}>
											<div className="form-group mb-0 d-flex align-items-center">
												<span className='pe-2 FindFor ps-3'>Find</span>
												<Autosuggest
													suggestions={suggestions}
													onSuggestionsFetchRequested={onSuggestionsFetchRequested}
													onSuggestionsClearRequested={onSuggestionsClearRequested}
													onSuggestionSelected={onSuggestionSelected}
													getSuggestionValue={getSuggestionValue}
													shouldRenderSuggestions={shouldRenderSuggestions}
													renderSuggestion={renderSuggestion}
													inputProps={inputProps}

												/>
											</div><span className="HBorder"></span>
											<div className="form-group mb-0">
												<Autosuggest1 location2={location2} Chandler={Chandler} />
											</div>
											<button className="button theme-btn" type="submit"><RiSearchLine /></button>
										</form>
									</div>

								</div>
							</div>
								<div className="main-menu-content listin_nav">
                                    <nav>
                                        <ul className="home-top-nav  d-flex justify-content-between">
                                            {categories.map((list, index) => {
                                                return (
                                                    <li key={`-${index}-${list.id}`}>
                                                        {/* <Link
                                                        to={{
                                                            pathname: `/listing/${list.name.replace(/\s+/g, '-').replace('&', "and").toLowerCase()}/${location.replace(`, `, '/').replace(/\s+/g, '-').toLowerCase().split("/").reverse().join("/")}`,
                                                            state: {
                                                                category_id: list.id,
                                                                category: list.name,
                                                                search: list.name,
                                                                location: location,
                                                                lat: lat,
                                                                lng: lng,
                                                            }
                                                        }}
                                                        >{list.name}</Link> */}
														<a href={`${frontLaravelUrl}/listing/${list.name.replace(/\s+/g, '-').replace('&', "and").toLowerCase()}/${location.replace(`, `, '/').replace(/\s+/g, '-').toLowerCase().split("/").reverse().join("/")}`} >{list.name}</a>
                                                    </li>
                                                );
                                            })}
                                            <li key={`manual`}>
												<a href={`${frontLaravelUrl}/all-categories`} >All Categories</a>
                                                {/* <Link to={{ pathname: frontLaravelUrl+`/all-categories`, }}>All Categories</Link> */}
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
							</div>
							<div className="top-right user-login-info MarginTop">
								{
									(uRole() == 1) ?
										<>
											{/* <div className="business theblock">
												<a className='d-none' href={`${buyerUrl}/theblock/${location && location.replace(`, `, '/').replace(/\s+/g, '-').toLowerCase().split("/").reverse().join("/")}`}>The Feed</a>
											</div> */}
											<div className="user-area">
												<div className="user-side directory">
													<Link to="/user-message"><img src={Chat} alt="Chat" />
													</Link>
												</div>
												<div className="user-side Chat">
													<Link to="/user-message"><img src={Notification} alt="Notification" />
													</Link>
												</div>

												<div className="user-side login DropMenu">

													<Dropdown>
														<Dropdown.Toggle variant="success" id="dropdown-basic">
															<img src={uInfo().image} alt="user" />
														</Dropdown.Toggle>
														<Dropdown.Menu>
															<UserInfoSignIn />
														</Dropdown.Menu>
													</Dropdown>
												</div>

											</div>
										</>
										: (uRole() == 2 || uRole() == 0) ?

											<>
												{/* <div className="business theblock">
													<a className='d-none' href={`${buyerUrl}/theblock/${location && location.replace(`, `, '/').replace(/\s+/g, '-').toLowerCase().split("/").reverse().join("/")}`}>The Feed</a>
												</div> */}
												<div className="user-area">
													<div className="user-side directory">
														<Link to="/message"><img src={Chat} alt="Chat" />
														</Link>
													</div>
													<div className="user-side Chat">
														<Link to="/message"><img src={Notification} alt="Notification" />
														</Link>
													</div>

													<div className="user-side login">
														<Link to="/dashboard"><img src={uInfo().image} alt="user" /><br />
															<span>{uInfo().full_name}</span></Link>
													</div>
													<div className="bell-icon">
														<Link onClick={LogoutBtn} title="logout" to="/" ><BsPower /></Link>
													</div>
												</div>
											</>
											:
											<>
												{/* <div className="business theblock">
													<a className='d-none' href={`${buyerUrl}/theblock/${location && location.replace(`, `, '/').replace(/\s+/g, '-').toLowerCase().split("/").reverse().join("/")}`}>The Feed</a>
												</div> */}
												<div className="login section">
													{/* <div className="login NewDesign me-2">
														<Link to="https://biz.katika.us">Join our community social network</Link>
													</div> */}
													<div className="login NewDesign">
														<ul>
															<li>
																<Dropdown className="dropdrown-list ">
																	<Dropdown.Toggle drop={'start'} variant="link" id="dropdown-basic">
																	Katika for Business <svg stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><polyline points="6 9 12 15 18 9"></polyline></svg>
																	</Dropdown.Toggle>
																	<Dropdown.Menu className="list-page-button" >
																	<a href={`${sellerUrl}/business-login`} > <AiOutlineShop /> Add a Business</a>
																	<a href={`${sellerUrl}/business-login`} > <FaRegCheckCircle /> Claim your business</a>
																	<a href={`${sellerUrl}/business-login`} > <AiOutlineUser /> Log in to Business Account</a>
																	<hr></hr>
																	<a href={`${sellerUrl}`} > <img src={KIcon} alt="Chat" /> &nbsp; Explore Katika for Business</a>


																		{/* <Link to="https://biz.katika.us/business-login"><AiOutlineShop /> Add a Business</Link>
																		<Link to="https://biz.katika.us/business-login"><FaRegCheckCircle /> Claim your business</Link>
																		<Link to="https://biz.katika.us/business-login"><AiOutlineUser /> Log in to Business Account</Link>
																		<hr></hr>
																		<Link className="MenuDropDown" to="https://biz.katika.us"><img src={KIcon} alt="Chat" /> Explore Katika for Business</Link>  */}
																	</Dropdown.Menu>
																</Dropdown>
																{/* <Dropdown className="dropdrown-list ">
																	<Dropdown.Toggle drop={'start'} variant="link" id="dropdown-basic">
																	Login <svg stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><polyline points="6 9 12 15 18 9"></polyline></svg>
																	</Dropdown.Toggle>
																	<Dropdown.Menu className="list-page-button" >
																		<Link to="/login"><AiOutlineUser /> Katika for Shoppers</Link>
																		<hr></hr>
																		<Link className="MenuDropDown" to="https://biz.katika.us/business-login"><AiOutlineShop /> Katika for Business</Link> 
																	</Dropdown.Menu>
																</Dropdown> */}
															</li>
														</ul>
													</div>
													{/* <div className="login NewDesign me-2">
													<Link to="https://biz.katika.us/business-login">Write a Review</Link>
													</div> */}
													<div className="login">
														<Link to="/login">Login</Link>
													</div>

													<div className="signup">
														<Link to={{ pathname: '/signup' }}>Sign Up</Link>
													</div>
												</div>
											</>
								}

							</div>
						</div>
					</div>
					{/* <div className="col-lg-4 col-md-12">

					</div> */}
				</div>
			</div>

			<div className="">
				<div className="row">
					<div className="col-lg-2"></div>
					<div className="col-lg-7 pe-0">

						<div className="side-menu-open" onClick={() => setNavOpen(!navOpen)}>
							<span className="menu__bar"></span>
							<span className="menu__bar"></span>
							<span className="menu__bar"></span>
						</div>
						<div className={navOpen ? 'side-nav-container active' : 'side-nav-container'}>
							<div className="humburger-menu">
								<div className="humburger-menu-lines side-menu-close" onClick={() => setNavOpen(!navOpen)}></div>
							</div>
							<div className="side-menu-wrap">
								{/* <ul className="side-menu-ul">

									{categories.map((cate, i) => {
										return (
											<li key={`-${i}-${cate.id}`}>
												<Link
													to={{
														pathname: `/listing/${cate.name.replace(/\s+/g, '-').replace('&', "and")}/${location.replace(`, `, '/').replace(/\s+/g, '-').toLowerCase().split("/").reverse().join("/")}`,
														state: {
															category_id: cate.id,
															category: cate.name,
															search: cate.name,
															location: location,
															lat: lat,
															lng: lng,
														}
													}}
												>{cate.name}</Link><span className="la-angle-down"><FiChevronDown /></span>

												<ul className="dropdown-menu-item">
													{cate.sub_category.map((sub, s) => {
														return (
															<li key={`-${s}-${sub.id}`}>
																<Link
																	to={{
																		pathname: `/listing/${sub.name.replace(/\s+/g, '-').replace('&', "and")}/${location.replace(`, `, '/').replace(/\s+/g, '-').toLowerCase().split("/").reverse().join("/")}`,
																		state: {
																			category_id: cate.id,
																			category: cate.name,
																			search: sub.name,
																			location: location,
																			sub_category_id: sub.id,
																			lat: lat,
																			lng: lng,
																		}
																	}}
																>{sub.name}</Link></li>
														);
													})}
												</ul>
											</li>
										);
									})}
									<li key={`manual`}>
										<Link
											to={{
												pathname: `/all-categories`,
											}}
										>More</Link>
									</li>
								</ul> */}

								{(uToken()) ?
									<div className="side-nav-button">
										<Link to="/user-profile" className="theme-btn">Profile</Link>
									</div>
									:
									<div className="side-nav-button">
										<Link to="/login" className="theme-btn">login</Link>
										<Link to="/sign-up" className="theme-btn">Sign up</Link>
									</div>
								}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}
