import React, { Component } from 'react';
import { Link, withRouter } from "react-router-dom";
import BMIcon from '../../assets/images/bm-icon.png'
import Dashboard from '../../assets/images/dashboard.svg'
import DetailedInformation from '../../assets/images/detailed-info.svg'
import Domain from "../../assets/images/domain-icon.svg"
import Website from "../../assets/images/website-icon.svg"
import Account from "../../assets/images/account-icon.svg"
import Messages from "../../assets/images/chat4.svg"
import Schedule from "../../assets/images/CTA_Icon.svg"
import Reviews from "../../assets/images/reviews.svg"
import Stats from "../../assets/images/stats.svg"
import BillingInformation from "../../assets/images/billing-information.png"
import Security from "../../assets/images/security.svg"
import ProgressBar from "react-bootstrap/ProgressBar";
import { ApiUrl } from '../../config';
import axios from 'axios';
import { uToken } from '../../useToken';
import Photo from "../../assets/images/gallery.svg"
import MyStory from '../../assets/images/MyStory.png';
import Starfilled from '../../assets/images/starfilled.png';
import ep_postcard from '../../assets/images/ep_postcard.svg';
import BusinessVideo from '../../assets/images/business-video.svg';
import megaphone from '../../assets/images/megaphone.svg';



class BusinessManager extends Component {

    constructor(props) {
        super(props)
        this.state = {
            percent: 0,
            menus: [
                {
                    icon: Website,
                    title: 'Website Dreamer',
                    url: '/website'
                },
                {
                    icon: Domain,
                    title: 'Domain',
                    url: '/domain'
                },
                {
                    icon: Account,
                    title: 'Account',
                    url: '/account'
                },
                {
                    icon: BusinessVideo,
                    title: 'Katika Business School Videos',
                    url: '/business-video',
                },
                {
                    icon: megaphone,
                    title: 'Social Media Manager',
                    url: '/media-manager'
                },
                {
                    icon: Dashboard,
                    title: 'Business Profile',
                    url: '/dashboard',
                    star: Starfilled
                },
                {
                    icon: DetailedInformation,
                    title: 'Detailed Information',
                    url: '/detailed-info',
                    star: Starfilled
                },

                {
                    icon: Photo,
                    title: 'Photo Gallery',
                    url: '/business-photos',
                    star: Starfilled
                },

                {
                    icon: Schedule,
                    title: 'Call To Action Links',
                    url: '/schedule',
                    star: Starfilled
                },

                {
                    icon: ep_postcard,
                    title: 'Create New Post',
                    url: '/create-post',
                },

                {
                    icon: ep_postcard,
                    title: 'Manage Post',
                    url: '/manage-post',
                },


                {
                    icon: Messages,
                    title: 'Messages',
                    url: '/message'
                },

                {
                    icon: Stats,
                    title: 'Stats',
                    url: '/stats'
                },
                {
                    icon: Reviews,
                    title: 'Reviews',
                    url: '/reviews'
                },
                {
                    icon: MyStory,
                    title: 'My Stories',
                    url: '/stories'
                },

                {
                    icon: BillingInformation,
                    title: 'Billing Information',
                    url: 'billing-history'
                },

                {
                    icon: Security,
                    title: 'Security',
                    url: '/security'
                }
            ]
        }
    }

    componentDidMount() {
        // if (this.props.percent) {
        //     this.setState({
        //         percent: this.props.percent
        //     })
        // } else {
        //     this.getBusinessProfilePercent();
        // }
        this.getBusinessProfilePercent();
    }

    getBusinessProfilePercent = () => {
        var self = this;
        axios.post(ApiUrl + 'userBusiness', {}, {
            headers: {
                'Authorization': `Bearer ${uToken()}`
            }
        })
            .then(function (res) {
                if (res.data.success === true) {
                    self.setState({
                        percent: res.data.data.complete_percent,
                        hasPost: res.data.hasPost,
                        hasSubscription: res.data.hasSubscription,
                    })
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    render() {
        return (
            <>
                <div className="sidebar section-bg mt-2 mb-4 side-navbar">
                    <div className="sidebar-widget side-menu">
                        <div className="author-bio text-align-center">
                            <div className="d-flex align-items-center">
                                <div className="dm-icon dm">
                                    <div className="dm-icon-img"><img src={BMIcon} alt="" /></div>
                                </div>
                                <div className="author-inner-bio pb-0 dm">
                                    <h4 className="author__title font-weight-bold pb-0 pl-1 ">
                                        Business Manager
                                    </h4>
                                </div>
                            </div>
                            <div className="ProgressBar">
                                <span>{this.state.percent}% Profile Complete</span>
                                <ProgressBar now={this.state.percent} />
                            </div>
                        </div>
                        <div className="section-block-2 mb-3"></div>
                        <div className="info-list">
                            <ul className="list-items">
                                {/* index == 4 && this.state.hasPost == 0 ?
                                                        <></>
                                                        :  */}
                                {this.state.menus.map((item, index) => {
                                    return (

                                        <li key={`siderbar${index}`}>
                                            {
                                                (index == 13 || index == 2) && this.state.hasSubscription == 0 ?
                                                    <></>
                                                    :

                                                    <Link to={item.url} className={(window.location.pathname == item.url) ? 'active' : ''}>
                                                        <span className="dashboard-icon"><img src={item.icon} alt="Dashboard Icon" /></span>
                                                        {item.title}
                                                        {
                                                            item.star ?
                                                                <span className="dashboard-icon right"><img src={item.star} alt="Dashboard Icon" /></span>
                                                                : ""
                                                        }
                                                    </Link>
                                            }
                                        </li>

                                    )
                                })}
                            </ul>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default BusinessManager;