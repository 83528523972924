import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import banner_img from '../../assets/images/business-main.jpg';
import mobile_img from '../../assets/images/biz-mobile1.png';
import mobile_img_bg from '../../assets/images/biz-mobile-bg.png';

export default class BannerOne extends Component {
    constructor(props) {
        super(props);
        this.state = {
            location: 'New York, NY',
        }
    }

    componentDidMount() {
        var loc = JSON.parse(localStorage.getItem('location'));
        if (loc != null) {

            this.setState({
                location: loc.city + ', ' + loc.region
            })

        }
    }

    render() {
        return (
            <>
                <section className="hero-wrapper2 bizbanner">
                    {/* <div className="hero-overlay"></div> */}
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-6 col-12 col-px-12">
                                <div className="biz-ligh-back">
                                    <div className="BannerContent text-center text-white d-flex">
                                    </div>
                                    <div className="BusinessInfo">
                                        <h1>Katika is built on the power of community.</h1>
                                        <p><b>Katika for business</b> connects your <b>black business</b>, non-profit, or public agency to the local communities you want to get your brand and information in front of.</p>
                                        
                                        {/* <button className="btn btn-default">Get Started for Free </button>  */}
                                        <Link to={'/business-sign-up'} className="btn btn-default">Create Your Free Katika Business Page</Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 px-0">
                                <div className="BusinessInfo banner_img desktop-show" style={{ backgroundImage: "url('" + banner_img + "')" }}>
                                    <div className="mobile-img"><img src={mobile_img} /></div>
                                </div>
                                <div className="BusinessInfo banner_img mobile-show" style={{ backgroundImage: "url('" + mobile_img_bg + "')" }}>
                                    <div className="mobile-img"><img src={mobile_img} /></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        )
    }
}
