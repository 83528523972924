import React, { useState, useEffect } from 'react';
import SignInOptions from "./SignInOptions";
import { Link } from "react-router-dom";
// import PropTypes from "prop-types";
import axios from "axios";
import { ApiUrl } from "../../../config"
import { ToastContainer, toast } from 'react-toastify';
import { useHistory } from "react-router-dom";
import useToken from '../../../useToken';



function LoginBox({ title, subtitle, props, redirect, handler1 , goFarward }) {
    const [email, setUserName] = useState();
    const [password, setPassword] = useState();
    const { setToken } = useToken();
    let history = useHistory();

    // begin user token
        const [userAuthToken, setUserAuthToken] = useState('');
        const getUrlAuthToken = () => {
            const paramsMatch = window.location.href.match(/\?.+/);            
            let authToken = '';
            if (paramsMatch) {
                const params = new URLSearchParams(paramsMatch[0]);
                authToken = params.get('auth_token');
                setUserAuthToken(authToken)
                const newUrl = window.location.origin + window.location.pathname;
                window.history.replaceState({}, "", newUrl);
            }
            console.log('seller authToken = ', authToken);

            return authToken
        };
        useEffect(() => {
            getUrlAuthToken();
        }, []);

        useEffect(() => {
            handleUserLoginWithAuthToken(userAuthToken);
        }, [userAuthToken]);
        
    // end user token
    const handleUserLoginWithAuthToken = (userAuthToken) => {
        axios.post(ApiUrl + 'loginWithAuthToken', {
            userAuthToken
        }, {
            'Content-Type': 'application/json',
            'Authorization': 'JWT fefege...'
        })
        .then(function (response) {
            console.log('seller response = ', response);
            console.log('seller response.data = ', response.data);
            console.log('seller response.success = ', response.success);
            if (response.data.success === true) {
                toast.success("Login Successful!");
                setToken(response.data);
                if (handler1) {
                    handler1(true)
                }
                if (redirect != '1234') {
                    if (response.data.role == '1') {
                        setTimeout(
                            () => history.push("/buyer-profile"),
                            4000
                        );
                    } else if (response.data.role == '2' || response.data.role == '0') {
                        if (goFarward != undefined) {
                            setTimeout(
                                () => history.go(goFarward),
                                4000
                            );
                        } else {
                            setTimeout(
                                () => history.push({
                                    pathname: '/dashboard',
                                    state: { prevPath: 'login', newLogin: response.data.login_first }
                                }),
                                4000
                            );
                        }
                    }

                }
            } else {
                toast.error(response.data.message);
            }
        })
        .catch(function (error) {
            console.log(error);
        });
    }
    const handleSubmit = async e => {

        e.preventDefault();
        if (email != '' && password != '') {
            axios.post(ApiUrl + 'loginSeller', {
                email,
                password
            }, {
                'Content-Type': 'application/json',
                'Authorization': 'JWT fefege...'
            })
                .then(function (response) {
                    if (response.data.success === true) {
                        toast.success("Login Successful!");
                        setToken(response.data);
                        if (handler1) {
                            handler1(true)
                        }
                        if (redirect != '1234') {
                            if (response.data.role == '1') {
                                setTimeout(
                                    () => history.push("/buyer-profile"),
                                    4000
                                );
                            } else if (response.data.role == '2' || response.data.role == '0') {
                                if (goFarward != undefined) {
                                    setTimeout(
                                        () => history.go(goFarward),
                                        4000
                                    );
                                } else {
                                    setTimeout(
                                        () => history.push({
                                            pathname: '/dashboard',
                                            state: { prevPath: 'login', newLogin: response.data.login_first }
                                        }),
                                        4000
                                    );
                                }
                            }

                        }
                    } else {
                        toast.error(response.data.message);
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
            // setToken({"token":"abc"});
        }
    }

    return (
        <>

            <div className="billing-form-item mb-0">
                <div className="billing-title-wrap border-bottom-0 pr-0 pl-0 pb-0 text-center">

                    <p className="font-size-16 font-weight-medium">
                        <h2>Sign in to your account</h2>
                    </p>
                </div>
                <div className="billing-content">
                    <div className="contact-form-action">
                        <form onSubmit={handleSubmit}>
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="input-box login">
                                        <div className="form-group">
                                            <input required="required" onChange={e => setUserName(e.target.value)} className="form-control" type="email" name="text" placeholder="Email" />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div className="input-box login">
                                        <div className="form-group no-margin">

                                            <input required="required" onChange={e => setPassword(e.target.value)} className="form-control" type="password" name="text" placeholder="Password" />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div className="form-group no-margin">
                                        <div className="custom-checkbox mr-0 d-flex align-items-center justify-content-between">
                                            <div>
                                                &nbsp;
                                            </div>
                                            <div>
                                                <Link to="/recover" className=" font-weight-medium">
                                                    Forgot password?
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div className="btn-box">
                                        <button className="theme-btn btn-login border-0" type="submit">
                                            Login
                                        </button>
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <p className="font-weight-medium">Don’t have an account?
                                        <Link
                                            to={{ pathname: '/business-sign-up', state: { prevPath: history.location.pathname } }}
                                        > Sign Up</Link></p>
                                    <p className="text-term-condition-login">By logging in, you agree to Katika’s <Link to='#'>Terms of Service</Link> and <Link to='#'> Privacy Policy.</Link></p>

                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <ToastContainer />
            </div>
        </>
    );
}

export default LoginBox;

// LoginBox.propTypes = {
//     setToken: PropTypes.func.isRequired
// };