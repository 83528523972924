import React, { Component } from 'react'
import SecondaryNavbar from './SecondaryNavbar'
import HeaderAuthorAccess from '../other/account/HeaderAuthorAccess'
import { Link } from "react-router-dom";
import { sellerUrl } from '../../config';


export default class GeneralHeader extends Component {
    render() {
        return (
            <>
                <header className="header-area">
                    <div className="header-menu-wrapper">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="menu-full-width">
                                        <div className="owner">
                                            <a href={sellerUrl}>Join our community social network</a>
                                        </div>
                                        {/* SecondaryNavbar */}
                                        <SecondaryNavbar />

                                        {/* Author Access */}
                                        {/* <HeaderAuthorAccess /> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
            </>
        )
    }
}
