import React, { useState, useEffect } from 'react'
import { FiChevronDown } from 'react-icons/fi'
import { BsPower } from 'react-icons/bs'
import { Link } from "react-router-dom";
import $ from 'jquery';
import { RiSearchLine } from 'react-icons/ri'
import Logo from "../common/Logo";
import { Container, Dropdown } from "react-bootstrap";
import UserInfoSignIn from "../../components/user/UserInfoSignIn";
import Logosrc from '../../assets/images/katika-logo.png';
import Navigation from '../../assets/images/navigation.png'
import User from '../../assets/images/user.png';
import Logout from '../../assets/images/signout.png';
import Chat from '../../assets/images/chat4.svg';
import Notification from '../../assets/images/bell.png';
import Autocomplete from 'react-google-autocomplete';
import { AutoApiKey, ApiUrl, algoliaAppId, algoliaAppKeyId, sellerUrl, buyerUrl, frontLaravelUrl } from '../../config';
import useToken, { getGeoInfo, uRole, uInfo, uToken } from '../../useToken';
import axios from 'axios';
import Scrollbar from 'smooth-scrollbar-react';
import Autosuggest from 'react-autosuggest';
import Autosuggest1 from "../directories/AutoSuggest";

import algoliasearch from "algoliasearch";
// import AlgolisAutocomplete from "algolia-react-autocomplete";
// import "algolia-react-autocomplete/build/css/index.css";


export default function Navbar(props) {
    const [token, setToken] = useState();
    const [navOpen, setNavOpen] = useState(false);
    const [search, setSearch] = useState("");
    const [location2, setLocation2] = useState(window.location.pathname.includes("/listing/") ? (props.state ? props.state.loc.replaceAll('-', ' ') + ', ' + props.state.state.toUpperCase() : "") : (props.state ? props.state.location : ""));
    const [location, setLocation] = useState(window.location.pathname.includes("/listing/") ? (props.state ? props.state.loc.replaceAll('-', ' ') + ', ' + props.state.state.toUpperCase() : "") : (props.state ? props.state.location : ""));





    const [lat, setLat] = useState(props.state ? props.state.lat : "")
    const [lng, setLng] = useState(props.state ? props.state.lng : "")
    const [showSuggest, setShowSuggest] = useState(false)
    const [categories, setCategories] = useState([]);
    const [place, setPlace] = useState();
    const [value, setValue] = useState("")
    const [suggestions, setSuggestion] = useState([])



    useEffect(() => {
        console.log(props, 'ssssssss')

        if (props.search_data) {
            setSearch(props.search_data.search);
            setLocation(props.search_data.location);
            setLocation2(props.search_data.location);
        } else if (props.state) {
            if (props.state.id != '' && props.state.id != undefined) {
                if (props.state.id == 'all') {
                    setValue("");
                } else {
                    let dtring = props.state.id.replace('-and-', ' & ').replaceAll('-', ' ');
                    setSearch(dtring);
                    setValue(dtring);
                }
            }

            // setLocation(props.state.loc);
            // setLocation2(props.state.loc);
        } else if (props.url) {
            setSearch(props.url)
            setValue('url')
        }


        if (!location || location == 'undefined, undefined') {
            var locn = JSON.parse(localStorage.getItem('location'));
            if (!locn) {
                if (getGeoInfo() == undefined) {
                    setLocation('Philadelphia, PA');
                    setLocation2('Philadelphia, PA');
                    setLat(39.9525839)
                    setLat(-75.1652215)
                } else {
                    setLocation(getGeoInfo())
                    setLocation2(getGeoInfo());
                    setLat(locn.latitude)
                    setLat(locn.longitude)
                }

            } else {
                let string = locn.city + ', ' + locn.region;
                setLocation(string)
                setLocation2(string);
                setLat(locn.latitude)
                setLat(locn.longitude)
            }
            console.log(location, 'addd loation')
        }

        console.log(location, 'hi loation')
        getSearchPageCategoriesList();
    }, []);

    const getSuggestionValue = (suggestion) => (suggestion.business_name) ? suggestion.business_name : suggestion.name
    // const renderSuggestion = (suggestion) => (suggestion.business_name ? <span>{suggestion.business_name}</span> : <span>{suggestion.name}</span>)
    const renderSuggestion = (suggestion, { query }) => <div className='bg-search-box'>{(suggestion.business_name ? <span>{suggestion.business_name}</span> : <span>{suggestion.name}</span>)} {query.length > 0 && (<span className='bg-search'>{query}</span>)}</div>;

    // Autosuggest will pass through all these props to the input.

    const onChange = (event, { newValue, method }) => {
        setValue(newValue)
        setSearch(newValue)
    }

    function onSuggestionsFetchRequested({ value }) {
        const client = algoliasearch(algoliaAppId, algoliaAppKeyId);
        const index = client.initIndex('katika_business');
        // Search for a first name
        index.search(value, {
            numericFilters: `is_active = 1,complete_percent > 69`,
            hitsPerPage: 10,
        }).then(({ hits }) => {
            setSuggestion(hits)
        });
        // fetch(`${ApiUrl}searchBusinessSuggestion?search=${value}`)
        //     .then(response => response.json())
        //     .then(data => setSuggestion(data.lists))
    }


    function onSuggestionsClearRequested() {
        setSuggestion([]);
    };
    function shouldRenderSuggestions() {
        return true;
    };

    const getSearchPageCategoriesList = () => {
        axios.get(ApiUrl + 'getSearchPageCategoriesList').then((response) => {
            if (response.data.success) {
                setCategories(response.data.lists)
            }
        }).catch((error) => {
            console.log(error);
        });
    }

    $(document).on('click', '.side-menu-ul li', function () {
        $(".side-menu-ul li").removeClass('active');
        $(this).toggleClass("active")
    })
    $(document).on('click', '.side-menu-ul li.active', function () {
        $(".side-menu-ul li.active").removeClass('active');
    })

    function LogoutBtn() {
        setToken('');
        localStorage.removeItem('token');
    }

    function Chandler(e) {
        let loca1 = JSON.parse(localStorage.getItem('location'));
        setLocation(loca1.city + ', ' + loca1.region)
        setLocation2(loca1.city + ', ' + loca1.region);
        setLat(loca1.latitude)
        setLng(loca1.longitude)
    }

    const onSuggestionSelected = (event, { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }) => {
        if (suggestion.business_name) {
            setValue(suggestion.business_name);
            setSearch(suggestion.business_name)
            props.handler({ search: suggestion.business_name, location: location, lat: lat, lng: lng })
        } else {
            setValue(suggestion.name);
            setSearch(suggestion.name)
            props.handler({ search: suggestion.name, location: location, lat: lat, lng: lng })
        }
        // console.log(suggestion.name ,'suggestion')
    }

    const handleSubmit = async e => {
        e.preventDefault();
        props.handler({ search: search, location: location, lat: lat, lng: lng, location2: location2 })
    }

    const inputProps = {
        placeholder: 'Find any business or service',
        value,
        type: "text",
        onChange: onChange
    };

    // const client = algoliasearch(algoliaAppId, algoliaAppKeyId);
    // const indexes = [
    //     // {
    //     //     source: client.initIndex("items_index"),
    //     //     displayKey: 'title',
    //     //     templates: {
    //     //         header: () => <h2 className="aa-suggestions-category"> Players</h2>,
    //     //         suggestion: (props, isSelected) => <li>{props.title}</li>
    //     //     }
    //     // },
    //     {
    //         source: client.initIndex("katika_restaurant"),
    //         displayKey: 'business_name',
    //         templates: {
    //             header: () => <h2 className="aa-suggestions-category">List</h2>,
    //             suggestion: (props, isSelected) => <li data-selected={isSelected}>{props.business_name}</li>
    //         }
    //     }
    // ];


    // const onChage1 = (e) => {
    //     console.log(e , 'b')
    //     // setSearch(e.target.value)
    // }

    // const onChage = (e) => {
    //     console.log(e.target.value ,'a')
    //     onSelectionChange(e.target.value)
    //     // setSearch(e.target.value)
    // }

    // const onSelectionChange = (selection) => {
    //     console.log(selection ,'abc')
    //     if (selection.title) {
    //         setSearch(selection.title)
    //     } else if(selection.business_name) {
    //         setSearch(selection.business_name)
    //     }else{
    //         setSearch(selection)
    //     }
    // };    

    return (
        <div className="container-fluid nav-listing-cat">
            <div className="nav-listing-search">
                <div className="row">
                    <div className="col-lg-2 col-md-12">
                        <div className="logo listing">
                            <Logo url={Logosrc} />
                        </div>
                    </div>
                    <div className="col-lg-7 col-md-12 pr-0">
                        <div className="search-feild">
                            <div className="main-search-input-item search-input">
                                <div className="contact-form-action">
                                    <form onSubmit={handleSubmit}>
                                        <div className="form-group mb-0">
                                            {/* <input onChange={e => setSearch(e.target.value)} value={search} className="form-control" type="text" placeholder="Find any business or service" /> */}
                                            {/* <AlgolisAutocomplete indexes={indexes}
                                                onSelectionChange={onSelectionChange}
                                                defaultValue={14}
                                                onChange={() => console.log('jhe')}
                                            >
                                                <input key="input" value="Hi" type="text" className="aa-input-search form-control"
                                                    placeholder=" jerk chicken, barbers, and brunch..."
                                                    onKeyUp={(e) => onChage(e)}
                                                    autoComplete="off"
                                                />

                                            </AlgolisAutocomplete> */}
                                            <Autosuggest
                                                suggestions={suggestions}
                                                onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                                                onSuggestionsClearRequested={onSuggestionsClearRequested}
                                                onSuggestionSelected={onSuggestionSelected}
                                                getSuggestionValue={getSuggestionValue}
                                                shouldRenderSuggestions={shouldRenderSuggestions}
                                                renderSuggestion={renderSuggestion}
                                                inputProps={inputProps}

                                            />
                                        </div><span className="HBorder"></span>
                                        <div className="form-group mb-0">


                                            <Autosuggest1 location2={location2} Chandler={Chandler} />

                                        </div>
                                        {/* {!showSuggest ?
                                            <div className="form-group mb-0">
                                                {showSuggest ? <Autosuggest1 location2={this.state.location2} Chandler={this.Chandler} /> : ""}

                                            </div>
                                            :
                                            <div className="form-group mb-0">
                                                <Autocomplete
                                                    apiKey={AutoApiKey}
                                                    placeholder=""
                                                    // style={{ width: '100%' }}
                                                    onPlaceSelected={(place) => {
                                                        let city = '';
                                                        let region = '';
                                                        if (place) {
                                                            place.address_components.map(adre => {
                                                                if (adre.types[0] == 'locality' && adre.types[1] == 'political') {
                                                                    city = adre.short_name;
                                                                }

                                                                if (adre.types[0] == 'administrative_area_level_1' && adre.types[1] == 'political') {
                                                                    region = adre.short_name;
                                                                }

                                                            })
                                                        }
                                                        setLocation(city + ', ' + region)
                                                        setPlace(place)
                                                        setLat(place.geometry.location.lat())
                                                        setLng(place.geometry.location.lng())
                                                        setShowSuggest(false)

                                                    }}
                                                    onKeyPress={e => {
                                                        if (e.key === 'Enter') e.preventDefault();
                                                    }}
                                                    autoFocus={true}
                                                    className="form-control c1 near-feild"
                                                    fields={[
                                                        'address_components',
                                                        'geometry.location',
                                                        'place_id',
                                                        'formatted_address'
                                                    ]}
                                                    // types={['(places)']}
                                                    componentRestrictions={{ country: "us" }}
                                                />
                                            </div>
                                        } */}
                                        <button className="button theme-btn" type="submit"><RiSearchLine /></button>
                                    </form>
                                    {/* <form action="#">
                                        <div className="form-group mb-0">
                                            <input className="form-control" type="text" placeholder="Find any business or service" />
                                        </div>
                                        <div className="main-search-input-item location"> <SelectCountry /> </div>
                                        <div className="main-search-input-btn">
                                            <button className="button theme-btn" type="submit"><RiSearchLine /></button>
                                        </div>
                                    </form> */}
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-12">
                        <div className="top-right user-login-info">
                            {
                                (uRole() == 1) ?
                                    <>
                                        <div className="business theblock">
                                            <a className='d-none' href={`${buyerUrl}/theblock/${location && location.replace(`, `, '/').replace(/\s+/g, '-').toLowerCase().split("/").reverse().join("/")}`}>The Feed</a>
                                        </div>
                                        <div className="user-area">
                                            <div className="user-side directory">
                                                <Link to="/user-message"><img src={Chat} alt="Chat" />
                                                </Link>
                                            </div>
                                            <div className="user-side Chat">
                                                <Link to="/user-message"><img src={Notification} alt="Notification" />
                                                </Link>
                                            </div>

                                            <div className="user-side login DropMenu">

                                                <Dropdown>
                                                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                                                        <img src={uInfo().image} alt="user" />
                                                        {/* <br /> */}
                                                        {/* <span>{uInfo().full_name}</span> */}
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        <UserInfoSignIn />
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </div>

                                        </div>
                                    </>
                                    : (uRole() == 2 || uRole() == 0) ?

                                        <>
                                            <div className="business theblock">
                                                <a className='d-none' href={`${buyerUrl}/theblock/${location && location.replace(`, `, '/').replace(/\s+/g, '-').toLowerCase().split("/").reverse().join("/")}`}>The Feed</a>
                                            </div>
                                            <div className="user-area">
                                                <div className="user-side directory">
                                                    <Link to="/message"><img src={Chat} alt="Chat" />
                                                    </Link>
                                                </div>
                                                <div className="user-side Chat">
                                                    <Link to="/message"><img src={Notification} alt="Notification" />
                                                    </Link>
                                                </div>

                                                <div className="user-side login">
                                                    <Link to="/dashboard"><img src={uInfo().image} alt="user" /><br />
                                                        <span>{uInfo().full_name}</span></Link>
                                                </div>
                                                <div className="bell-icon">
                                                    <Link onClick={LogoutBtn} title="logout" to="/" ><BsPower /></Link>
                                                </div>
                                            </div>
                                        </>
                                        :
                                        <>
                                            <div className="business theblock">
                                                <a className='d-none' href={`${buyerUrl}/theblock/${location && location.replace(`, `, '/').replace(/\s+/g, '-').toLowerCase().split("/").reverse().join("/")}`}>The Feed</a>
                                            </div>
                                            <div className="login section">
                                                <div className="login">
                                                    <ul>
                                                        <li>
                                                            <Dropdown className="dropdrown-list ">
                                                                <Dropdown.Toggle drop={'start'} variant="link" id="dropdown-basic">
                                                                    Log in
                                                                </Dropdown.Toggle>
                                                                <Dropdown.Menu className="list-page-button" >
                                                                    <Link to="/login">Katika for Shoppers</Link>
                                                                    <a href={`${sellerUrl}/business-login`} > Katika for Business</a>
                                                                </Dropdown.Menu>

                                                            </Dropdown>
                                                        </li>
                                                    </ul>

                                                    {/* <Link to={`/login`}>Login</Link> */}
                                                </div>

                                                <div className="signup">
                                                    <Link to={{ pathname: '/signup' }}>Sign Up</Link>
                                                </div>
                                            </div>


                                            {/* <div className="user-area">
                                                <div className="user-side directory">
                                                    <Link to="/login">Login
                                                    </Link>
                                                </div>
                                                <div className="user-side login">
                                                    <Link to="/login"><img src={User} alt="user" /><br />
                                                        <span>Login</span></Link>
                                                </div> 
                                                 <div className="bell-icon">
                                                    <Link to="/login"><img src={User} alt="Bell" /></Link>
                                                </div> 
                                            </div> */}
                                        </>
                            }

                        </div>
                    </div>
                </div>
            </div>

            <div className="">
                <div className="row">
                    <div className="col-lg-2"></div>
                    <div className="col-lg-10">
                        <div className="main-menu-content listin_nav">
                            <nav>
                                <ul>
                                    {categories.map((cate, i) => {
                                        return (
                                            <li key={`${i}-${cate.id}`}>
                                                {/* <Link
                                                    to={{
                                                        pathname: `/listing/${cate.name.replace(/\s+/g, '-').replace('&', "and").toLowerCase()}/${location.replace(`, `, '/').replace(/\s+/g, '-').toLowerCase().split("/").reverse().join("/")}`,
                                                        // pathname: `/listing/${cate.name}/${location}`,
                                                        state: {
                                                            category_id: cate.id,
                                                            category: cate.name,
                                                            search: cate.name,
                                                            location: location,
                                                            lat: lat,
                                                            lng: lng,
                                                        }
                                                    }}
                                                >{cate.name} <FiChevronDown /></Link> */}
                                                <a href={`${frontLaravelUrl}/listing/${cate.name.replace(/\s+/g, '-').replace('&', "and").toLowerCase()}/${location.replace(`, `, '/').replace(/\s+/g, '-').toLowerCase().split("/").reverse().join("/")}`} >{cate.name} <FiChevronDown /></a>


                                                <Scrollbar alwaysShowTracks={true}>
                                                    <ul className="dropdown-menu-item" style={{ maxHeight: '395px' }}>
                                                        {cate.sub_category.map((sub, s) => {
                                                            return (
                                                                <li key={`${s}-${sub.id}`}>
                                                                    {/* <Link
                                                                        to={{
                                                                            pathname: `/listing/${sub.name.replace(/\s+/g, '-').replace('&', "and").toLowerCase()}/${location.replace(`, `, '/').replace(/\s+/g, '-').toLowerCase().split("/").reverse().join("/")}`,
                                                                            // pathname: `/listing/${sub.name}/${location}`,
                                                                            state: {
                                                                                category_id: cate.id,
                                                                                category: cate.name,
                                                                                search: sub.name,
                                                                                location: location,
                                                                                sub_category_id: sub.id,
                                                                                lat: lat,
                                                                                lng: lng,
                                                                            }
                                                                        }}
                                                                    >{sub.name}</Link> */}
                                                                    <a href={`${frontLaravelUrl}/listing/${sub.name.replace(/\s+/g, '-').replace('&', "and").toLowerCase()}/${location.replace(`, `, '/').replace(/\s+/g, '-').toLowerCase().split("/").reverse().join("/")}`} >{sub.name}</a>    
                                                                </li>
                                                            );
                                                        })}
                                                    </ul>
                                                </Scrollbar>
                                            </li>
                                        );
                                    })}

                                    <li key={`manual`}>
                                        {/* <Link
                                            to={{
                                                pathname: `/all-categories`,
                                            }}
                                        >More</Link> */}
                                        <a href={`${frontLaravelUrl}/all-categories`} >More</a>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                        <div className="side-menu-open" onClick={() => setNavOpen(!navOpen)}>
                            <span className="menu__bar"></span>
                            <span className="menu__bar"></span>
                            <span className="menu__bar"></span>
                        </div>
                        <div className={navOpen ? 'side-nav-container active' : 'side-nav-container'}>
                            <div className="humburger-menu">
                                <div className="humburger-menu-lines side-menu-close" onClick={() => setNavOpen(!navOpen)}></div>
                            </div>
                            <div className="side-menu-wrap">
                                <ul className="side-menu-ul">

                                    {categories.map((cate, i) => {
                                        return (
                                            <li key={`-${i}-${cate.id}`}>
                                                <Link
                                                    to={{
                                                        pathname: `/listing/${cate.name.replace(/\s+/g, '-').replace('&', "and")}/${location.replace(`, `, '/').replace(/\s+/g, '-').toLowerCase().split("/").reverse().join("/")}`,
                                                        // pathname: `/listing/${cate.name.replace(/[^A-Z0-9]+/ig, '-').toLowerCase()}`,
                                                        state: {
                                                            category_id: cate.id,
                                                            category: cate.name,
                                                            search: cate.name,
                                                            location: location,
                                                            lat: lat,
                                                            lng: lng,
                                                        }
                                                    }}
                                                >{cate.name}</Link><span className="la-angle-down"><FiChevronDown /></span>

                                                <ul className="dropdown-menu-item">
                                                    {cate.sub_category.map((sub, s) => {
                                                        return (
                                                            <li key={`-${s}-${sub.id}`}>
                                                                <Link
                                                                    to={{
                                                                        pathname: `/listing/${sub.name.replace(/\s+/g, '-').replace('&', "and")}/${location.replace(`, `, '/').replace(/\s+/g, '-').toLowerCase().split("/").reverse().join("/")}`,
                                                                        // pathname: `/listing/${sub.name.replace(/[^A-Z0-9]+/ig, '-').toLowerCase()}`,
                                                                        state: {
                                                                            category_id: cate.id,
                                                                            category: cate.name,
                                                                            search: sub.name,
                                                                            location: location,
                                                                            sub_category_id: sub.id,
                                                                            lat: lat,
                                                                            lng: lng,
                                                                        }
                                                                    }}
                                                                >{sub.name}</Link></li>
                                                        );
                                                    })}
                                                </ul>
                                            </li>
                                        );
                                    })}
                                    <li key={`manual`}>
                                        <Link
                                            to={{
                                                pathname: `/all-categories`,
                                            }}
                                        >More</Link>
                                    </li>
                                </ul>

                                {(uToken()) ?
                                    <div className="side-nav-button">
                                        <Link to="/user-profile" className="theme-btn">Profile</Link>
                                    </div>
                                    :
                                    <div className="side-nav-button">
                                        <Link to="/login" className="theme-btn">login</Link>
                                        <Link to="/sign-up" className="theme-btn">Sign up</Link>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
