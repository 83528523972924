import React, { Component, Suspense } from 'react'
/*import BannerOneHeroHeading from './BannerOneHeroHeading'*/
import BannerOneSearchInput from './BannerOneSearchInput'
// import HighlightedCategory from "../other/categories/HighlightedCategory";
import banner_img from '../../assets/images/banner_img.png';
// import BottomHeading from '../directories/BottomHeading'
import { ApiUrl } from '../../config';
import axios from 'axios';
import { Link } from 'react-router-dom';
// import Overlay from 'react-bootstrap/Overlay'
// import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
// import Button from 'react-bootstrap/Button'
// import Tooltip from 'react-bootstrap/Tooltip'
import Rating from 'react-rating';
import Empty from '../../assets/images/empty.png';
import Half from '../../assets/images/half.png';
import Fill from '../../assets/images/fill.png';

// begin slider
    import Slider from "react-slick";
    // import "slick-carousel/slick/slick.css";
    // import "slick-carousel/slick/slick-theme.css";
    import '../../assets/css/bannerOne.css'; // Import your custom CSS file for styling
// end slider

export default class BannerOne extends Component {
    constructor(props) {
        super(props);
        this.state = {
            location: 'Philadelphia, PA',
            city: 'Philadelphia',
            externalData: false,
            img: '',
            isMouseInside: false,
            sliderItems: [],

        }
    }

    handleBoxToggle = () => this.setState({ isMouseInside: true });

    handleBoxHide = () => this.setState({ isMouseInside: false });


    componentDidMount() {
        var loc = JSON.parse(localStorage.getItem('location'));

        if (loc != null) {
            this.setState({
                location: loc.city + ', ' + loc.region,
                city: loc.city
            })
            this.getLandingPageBackgroundImage(loc.city);
        } else {
            this.getLandingPageBackgroundImage(this.state.city);
        }

    }

    getLandingPageBackgroundImage = (city) => {
        var self = this;
        self.setState({
            externalData: false,
        })

        axios.post(ApiUrl + 'v2/getLandingPageBackgroundImage', { city: city }, {
            headers: {}
        })

        // 'background_image'  =>   $value->background_image,                
        // 'business_name'     =>   $getBusiness['business_name'] ?? "",
        // 'business_url'      =>   $value->business_url,                
        // 'business_rating'   =>   $getBusiness['business_rating'] ?? "",
        // 'business_slug'     =>   (!empty($$getBusiness)) ? $getBusiness['business_slug'].'-'.strtolower($getBusiness['business_state']) : "",
        // 'business_reviews'  =>   $getBusiness['business_reviews'] ?? "",
        // 'business_category' =>   (!empty($$getBusiness)) ? $getBusiness['category'].', '.$getBusiness['subcategory'] : "",
        // 'user_name'         =>   $value->user->fname.' '.$value->user->lname,                
        // 'user_id'           =>   $value->user_id,                
        // 'id'                =>   $value->id,
                   
    
            .then(function (res) {
                if (res.data.success === true) {
                    self.setState({
                        // img: res.data.lists.background_image,
                        // name: res.data.lists.business_name,
                        // url: res.data.lists.business_url,
                        // business_rating: res.data.lists.business_rating,
                        // business_slug: res.data.lists.business_slug,
                        // business_reviews: res.data.lists.business_reviews,
                        // business_category: res.data.lists.business_category,
                        externalData: true,
                        // uName: res.data.lists.user_name,
                        sliderItems: res.data.lists
                    })
                } else {
                    self.setState({
                        img: banner_img,
                        externalData: true,
                    })
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    // begin slider
        responsive = [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
        
    // end slider
    render() {
        // begin slider
            const settings = {
                dots: true,
                infinite: true,
                speed: 500,
                slidesToShow: 1,
                slidesToScroll: 1,
                autoplay: true,
                autoplaySpeed: 5000,
            };
        // end slider
        return (
            <>
                <section className="hero-wrapper hero-wrapper2 NoBg">
                    {/* <img width={'100%'} height={'620px'} className="BannerImages" src={this.state.img }  alt="Card cap"/> */}
                    <Slider {...settings}>
                        {
                            (this.state.sliderItems.length > 0) ?

                                this.state.sliderItems.map((sliderItem, i) => {
                                    i++;
                                    return (
                                        <div className="slide-item" key={i}>
                                            <div className='container'>
                                                <div className="slide-content">
                                                    <div className='sliderContent'>
                                                        <h1>{sliderItem.title}</h1>
                                                        <p>{sliderItem.sub_title}</p>
                                                        {
                                                            (sliderItem.action_url == null || sliderItem.action_url == "") ?
                                                                <></>
                                                            :
                                                                <Link className="button theme-btn" to={{
                                                                    pathname: `${sliderItem.action_url}`,
                                                                }} >Action</Link>
                                                        }
                                                    </div>
                                                    {/* begin business */}
                                                        <div className="bottom-heading main-page" >
                                                            <div className="section-heading display-inline">
                                                                <div className="col-lg-12">
                                                                    <div className={`ShowHover ${(this.state.isMouseInside) ? " d-flex" : "d-none "}`} onMouseEnter={this.handleBoxToggle} onMouseLeave={this.handleBoxHide}>
                                                                        <div className="HoverItem" key={0}>
                                                                            <div className="hover-image">
                                                                                <img width={'90'} height={'90'} src={sliderItem.background_image} className="img-responsive" alt='Logo' />
                                                                            </div>
                                                                        </div>
                                                                        <div className="DownArrow"></div>
                                                                        <div className="hover-active-details">
                                                                            <Link to={{
                                                                                pathname: `/biz/${sliderItem.business_slug}`,
                                                                            }} >{sliderItem.business_name}</Link>
                                                                            <div className="rating-rating  rating-detail landing">
                                                                                <Rating
                                                                                    placeholderRating={sliderItem.business_rating}
                                                                                    readonly={true}
                                                                                    stop={5}
                                                                                    emptySymbol={<img src={Empty} className="icon" />}
                                                                                    placeholderSymbol={<img src={Fill} className="icon" />}
                                                                                    fullSymbol={<img src={Fill} className="icon" />}
                                                                                />
                                                                                <p className="count">{sliderItem.business_reviews} reviews</p>
                                                                                <br />
                                                                                <p className="count">{sliderItem.business_category}</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <Link to={{
                                                                    pathname: `/biz/${sliderItem.business_slug}`,
                                                                }} className="mouseOver" onMouseEnter={this.handleBoxToggle} onMouseLeave={this.handleBoxHide}>
                                                                    <h4 className={'sec__title ' + this.state.uNamea}>{sliderItem.business_name}</h4>
                                                                </Link>
                                                                <br />
                                                                <a href={this.state.url} className="display-inline">
                                                                    <p className={'main-p sec__desc ' + this.state.uNamea}> {this.state.location}</p>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    {/* end business */}
                                                </div>
                                            </div>
                                            <img src={sliderItem.background_image}  alt="Slider Item" />
                                        </div>
                                    );

                                })
                            :  
                            <div className="slide-item" key="slider_default_image">
                                <div className='container'>
                                    <div className="slide-content">
                                        <div className='sliderContent'>
                                            {/* <h1>{sliderItem.title}</h1>
                                            <p>{sliderItem.sub_title}</p>
                                            <Link className="button theme-btn" to={{
                                                pathname: `${sliderItem.action_url}`,
                                            }} >Action</Link> */}

                                        </div>
                                    </div>
                                </div>
                                <img src={this.state.img}  alt="Slider Item" />
                            </div>
                        }
                        {/* <div className="slide-item" key="1">
                            <div className="slide-content">
                                <h1>Heading 1</h1>
                                <p>Description 1</p>
                                <button>Action 1</button>
                            </div>
                            <img src={this.state.img} alt="Slider Item" />
                        </div> */}
                    </Slider>
                    {/* <div className="hero-overlay"></div> */}
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <Suspense fallback={<div>Loading...</div>}>
                                    {/* Banner One Search Input */}
                                    {/* <BannerOneSearchInput /> */}
                                </Suspense>
                                {/* Banner One Categories */}
                                {/* <HighlightedCategory /> */}

                                {/* Banner One Hero Heading */}
                                {/* <BannerOneHeroHeading /> */}


                            </div>
                        </div>
                    </div>

                    {/* <div className="bottom-heading main-page" >
                        <div className="section-heading display-inline">
                            <div className="col-lg-12">
                                <div className={`ShowHover ${(this.state.isMouseInside) ? " d-flex" : "d-none "}`} onMouseEnter={this.handleBoxToggle} onMouseLeave={this.handleBoxHide}>
                                    <div className="HoverItem" key={0}>
                                        <div className="hover-image">
                                            <img width={'90'} height={'90'} src={this.state.img} className="img-responsive" alt='Logo' />
                                        </div>
                                    </div>
                                    <div className="DownArrow"></div>
                                    <div className="hover-active-details">
                                        <Link to={{
                                            pathname: `/biz/${this.state.business_slug}`,
                                        }} >{this.state.name}</Link>
                                        <div className="rating-rating  rating-detail landing">
                                            <Rating
                                                placeholderRating={this.state.business_rating}
                                                readonly={true}
                                                stop={5}
                                                emptySymbol={<img src={Empty} className="icon" />}
                                                placeholderSymbol={<img src={Fill} className="icon" />}
                                                fullSymbol={<img src={Fill} className="icon" />}
                                            />
                                            <p className="count">{this.state.business_reviews} reviews</p>
                                            <br />
                                            <p className="count">{this.state.business_category}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <Link to={{
                                pathname: `/biz/${this.state.business_slug}`,
                            }} className="mouseOver" onMouseEnter={this.handleBoxToggle} onMouseLeave={this.handleBoxHide}>
                                <h4 className={'sec__title ' + this.state.name}>{this.state.name}</h4>
                            </Link>
                            <br />
                            <a href={this.state.url} className="display-inline">
                                <p className={'main-p sec__desc ' + this.state.uNamea}> {this.state.location}</p>
                            </a>
                        </div>
                    </div> */}
                </section>
            </>
        )
    }


}




