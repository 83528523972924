import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import { FaTwitter, FaFacebookF, FaInstagram, FaYoutube } from "react-icons/fa";
import { buyerUrl, frontLaravelUrl } from '../../../config';


class CopyrightMenu extends Component {
    state = {
        links: [
            {
                path: '/terms-of-service',
                title: 'Terms of Service'
            },
            {
                path: '/privacy-policy',
                title: 'Privacy Policy'
            }
        ]
    }
    render() {
        return (
            <>
                <div className="copyright-left-side">
                    <ul className="list-items">
                        {this.state.links.map((link, index) => {
                            return (
                                <li key={index}>
                                    <a href={`${frontLaravelUrl}${link.path}`} >{link.title}</a>
                                </li>
                            )
                        })}
                        <li className="d-none d-lg-block d-xl-block pr-0 border-0"> Copyright &copy;  {new Date().getFullYear()} Katika All Rights Reserved. Made With Passion In Philadelphia, PA
                        </li>
                        <li className="d-block d-sm-none px-0"> &copy; <b>Katika, LLC {new Date().getFullYear()}</b>
                        </li>
                    </ul>
                </div>
                <div className="copyright-right-side justify-content-end d-flex">
                    <ul className="list-items SocialIcons">
                        <li><a href="https://twitter.com/katikausa" target="_blank"><FaTwitter /></a> </li>
                        <li><a href="https://www.facebook.com/katikausa" target="_blank"><FaFacebookF /></a> </li>
                        <li><a href="https://www.instagram.com/katikausa/" target="_blank"><FaInstagram /></a> </li>
                        <li><a href="https://www.youtube.com/channel/UC6ML8hqMCowM6yMXZt8CmuQ" target="_blank"><FaYoutube /></a> </li>
                    </ul>
                </div>
            </>
        );
    }
}

export default CopyrightMenu;