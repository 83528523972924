import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { buyerUrl, sellerHost, sellerUrl, frontLaravelUrl } from '../../../config';
import { uToken } from '../../../useToken';

class FooterHelpWidget extends Component {
    state = {
        title: 'Let us help you',
        links: [
            {
                path: '/',
                text: 'List your Business'
            },
            {
                path: '/faq',
                text: 'Frequently Asked Questions'
            }
        ]
    }
    render() {
        return (
            <>
                <div className="col-lg-3 col-md-3 col-sm-3 column-td-6">
                    <div className="footer-item">
                        <h4 className="footer__title">
                            {this.state.title}
                        </h4>
                        <ul className="list-items">
                            <li key={0}>
                                <a href={`${sellerUrl}`} >List your Business</a>
                            </li>
                            <li key={1}>
                                <a href={`${frontLaravelUrl}/faq`} >Frequently Asked Questions</a>
                            </li>

                            <li key={3}>
                                <a href={`${sellerUrl}/plans`} >Plans & Pricing</a>
                            </li>

                            <li key={2}>
                                <a href={`${frontLaravelUrl}/get-help`} >Contact Us</a>
                            </li>


                        </ul>
                    </div>
                </div>
            </>
        );
    }
}

export default FooterHelpWidget;